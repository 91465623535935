<template>
  <div>
    <loginBG></loginBG>
    <el-main class="background-container">
      <div class="login-container-wrap">
        <div class="login-container">
          <div class="login-title-style">登录</div>
          <div class="divider-5"></div>

          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="form-container-L"
                   label-position="left">
            <el-form-item label="用户名" prop="username">
              <el-input v-model="ruleForm.username" style="width: 240px" size="small"></el-input>
            </el-form-item>

            <el-form-item label="密码" prop="password">
              <el-input type="password" v-model="ruleForm.password" style="width: 240px" size="small"></el-input>
            </el-form-item>

            <el-form-item label="验证码" prop="captcha">
              <el-input v-model="ruleForm.captcha" id="captcha_" style="width: 140px;position: relative;top: -10px"
                        size="small"></el-input>
              <img :src="checkCode" @click="getCheckCode" width="100" height="32"
                   style="margin-left: 10px;">
            </el-form-item>

            <el-form-item>
              <el-button type="primary" class="login-button-style" @click="submitForm('ruleForm')">立即登录</el-button>
              <!--              <el-button @click="resetForm('ruleForm')">重置</el-button>-->
            </el-form-item>
          </el-form>

<!--          <div style="float: left;margin: 5px 0 0 50px">-->
<!--            <router-link :to="{path:'/forgot_password'}" style="text-decoration-color: #b4b4b4;"><a-->
<!--                class="turn-to-word">忘记密码？</a>-->
<!--            </router-link>-->
<!--          </div>-->
          <div style="float: right;margin: 5px 60px 0 0">
            <router-link :to="{path:'/register'}" style="text-decoration-color: #b4b4b4;"><a class="turn-to-word">注册</a>
            </router-link>
          </div>
        </div>
      </div>
    </el-main>
    <footer>
      <div>Copyright©2010 All Rights Reserved. 版权所有 中国医学科学院 & 北京协和医学院</div>
      <div>地址：北京市东城区东单三条9号 邮编：100730</div>
    </footer>
  </div>
</template>
<script>
import loginBG from "@/components/loginBG";
import {randomNumber, randomUUID} from "@/utils/util";

export default {
  name: "Login",
  components: {
    loginBG
  },
  data() {
    return {
      ruleForm: {
        password: undefined,
        checkKey: undefined,
        captcha: undefined,
        username: undefined,
      },
      rules: {
        username: [
          {required: true, message: '用户名不能为空', trigger: 'blur'},
          {min: 3, max: 100, message: '长度在 3 到 100 个字符', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '密码不能为空', trigger: 'blur'},
          {min: 3, max: 100, message: '长度在 3 到 100 个字符', trigger: 'blur'}
        ],
        captcha: [
          {required: true, message: '验证码不能为空', trigger: 'blur'},
          {min: 4, max: 4, message: '长度在 4 个字符', trigger: 'blur'}
        ],

      },
      checkCode: undefined,
      errorMessage: '',
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.axios.post('/jeecg-boot/user/login', this.ruleForm).then(res => {
            console.log('res333=',res)
            //存token
            if (res.code === 200) {
              sessionStorage.setItem("token", res.result.token);
              sessionStorage.setItem("userId", res.result.userId);
              sessionStorage.setItem("userName", res.result.userName);
              // debugger
              this.$message.success('登录成功')
              setTimeout(()=>{
                this.$router.push({path: '/examPaperList'})
              },1000)
            }else{
              this.$message.error(res.message)
            }
          }).catch(function (error) {
            console.log(error)
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getCheckCode() {
      this.ruleForm.checkKey = randomUUID()
      this.axios.get('/jeecg-boot/sys/randomImage/' + this.ruleForm.checkKey).then((res) => {
        this.checkCode = res.result;
      })
    },
    submitForm2() {
      //表单验证
      this.$refs.ruleForm.validate((valid) => {
        // if (valid) {
        //   // 向后端登录请求
        //
        //   this.axios.post('/jeecg-boot/sys/login', this.loginList
        //   ).then(function (res) {
        //     //存token
        //     if (res.code === 200) {
        //       window.sessionStorage.setItem("token", res.result.token);
        //       window.sessionStorage.setItem("phone", this.loginList.checkKey);
        //       window.sessionStorage.setItem("userId", res.result.userInfo.id);
        //       window.sessionStorage.setItem("userName", res.result.userInfo.username);
        //       this.$router.push('/home')
        //     }
        //   }).catch(function (error) {
        //     console.log(error)
        //   })
        // } else {
        //   console.log('valid=', valid)
        // }
      })
      //
      // this.loginList.captcha = ''
      // this.getCheckCode(that.loginList.checkKey)
    }
  },
  mounted() {
    console.log(process.env.NODE_ENV)
    // 推出进入登录界面时，清空改登录用户的信息
    // window.localStorage.removeItem("token")
    // window.localStorage.removeItem("phone")
    // window.localStorage.removeItem("userId")
    // window.localStorage.removeItem("userName")
    //默认获取的验证码
    this.getCheckCode()
  },
  computed: {}
}
</script>

<style scoped>
.login-container {
  width: 445px;
  background: #FFFFFF;
  border-radius: 10px;
  margin-top: 40px;
  padding-bottom: 30px;
  float: right;
}

.login-container-wrap {
  width: 1300px;
  marign: 0 auto;
}

.login-button-style {
  width: 220px;
  height: 35px;
  background: #089BAB;
  border-radius: 6px;
  margin-top: 5px;
  border: none;
  box-shadow: none;
}

.form-container-L {
  margin: 15px 0 0 44px;
}

footer {
  width: 1300px;
  margin: 60px auto 0px;
  text-align: center;
  color: #888;
  font-size: 14px;
}

/deep/ .el-form-item {
  margin-bottom: 20px;
}
</style>
